import * as React from "react";
import { useRecoilState } from "recoil";
import { StudentReportControls } from "../../../../atoms";
import DatePicker from "../../../../components/DatePicker";

export default function BetweenDates() {
  const [controls, setControls] = useRecoilState(StudentReportControls);

  function handleStartChange(newValue) {
    setControls({
      ...controls,
      filters: {
        ...controls.filters,
        date: "",
        startDate: newValue,
      },
    });
  }
  function handleEndChange(newValue) {
    setControls({
      ...controls,
      filters: {
        ...controls.filters,
        date: "",
        endDate: newValue,
      },
    });
  }

  return (
    <div>
      <DatePicker
        placeholderText="Start Date"
        selected={controls.filters.startDate}
        onChange={handleStartChange}
      />
      <DatePicker
        placeholderText="End Date"
        selected={controls.filters.endDate}
        onChange={handleEndChange}
      />
    </div>
    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <DemoContainer components={["DatePicker"]}>
    //     <DatePicker
    //       value={controls.filters.startDate}
    //       onChange={handleStartChange}
    //       label="Start Date"
    //       slots={{
    //         textField: (params) => <TextField size="small" {...params} />,
    //       }}
    //     />
    //     <DatePicker
    //       value={controls.filters.endDate}
    //       onChange={handleEndChange}
    //       label="End Date"
    //       minDate={controls.filters.startDate}
    //       slots={{
    //         textField: (params) => <TextField size="small" {...params} />,
    //       }}
    //     />
    //   </DemoContainer>
    // </LocalizationProvider>
  );
}

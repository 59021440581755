import { useEffect } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CurrentBusiness, CurrentUser } from "../../atoms";
import Parse from "../../parse";

const authProvider = {
  authenticate: (options) => {
    // Some code to get retrieve authData
    // If retrieved valid authData, call success function with the data
    console.log("Starting auth with options ");
    console.log("options");
    console.log(options);
    options.success(this, {
      id: 1234,
    });
    // You can also handle error
    // options.error(this, {});
  },
  deauthenticate: () => {
    console.log("starting to DEEauthenticate");
    return null;
  },
  getAuthType: () => {
    console.log("Getting auth type");
    return "crtoken";
  },
  restoreAuthentication(data) {
    console.log("restoring authentication", data);
    return true;
  },
};

Parse.User._registerAuthenticationProvider(authProvider);

export default function Auth(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [business, setBusiness] = useRecoilState(CurrentBusiness);
  console.log(searchParams.get("token"));
  const token = searchParams.get("token");
  const [currentUser, setCurrentUser] = useRecoilState(CurrentUser);
  console.log("search params ", searchParams);

  // const source = searchParams.get('source');
  // const state = searchParams.get('state');

  async function login(token) {
    console.log("running logins");

    // const user = new Parse.User();

    try {
      const user = await Parse.User.logInWith("crtoken", {
        authData: {
          id: "crtoken",
          accessToken: token,
        },
      });
      // const business = await Parse
      const result = await Parse.Cloud.run("syncUserAccount");
      setBusiness(result);

      console.log(user.get("authData"));
      console.log(user.toJSON());

      console.log(user);

      console.log("User: ", user);
      console.log(user.toJSON());

      return user;
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAuth(id) {
    await Parse.User.logOut();
    console.log(id);
    const user = await login(token);
    console.log("setting the current user: ", user);
    setCurrentUser(user);
  }

  // http://localhost:3000/auth?source=shopify&state=23323223r&id=fZKKCrzCsB
  // handleAuth(id);
  // const currentUser = Parse.User.current();

  useEffect(() => {
    if (token) {
      handleAuth(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();

  if (currentUser) {
    console.log("WE HAVE A CURRENT USER");
    // return <div>Navigate</div>;

    // TODO: testing
    // return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <div>Exchange Token</div>;
}

import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-datepicker/dist/react-datepicker.css";
import { ChakraProvider } from "@chakra-ui/react";

import { ApolloProvider } from "@apollo/client";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";

import client from "./apollo";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Auth from "./pages/auth/Auth";
import Protected from "./components/Protected";

// console.log(Object.keys(process.env));

function Root() {
  return <App />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Protected>
        <Root />
      </Protected>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ChakraProvider>
          <RouterProvider router={router} />
        </ChakraProvider>
      </ApolloProvider>
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Parser } from "@json2csv/plainjs";

function downloadFile(fileName, data) {
  const downloadLink = document.createElement("a");
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

export async function exportToCsv(data, fileName) {
  try {
    const parser = new Parser();
    const csv = parser.parse(data);
    console.log(csv);
    downloadFile(
      fileName,
      new Blob([csv], { type: "text/csv;charset=utf-8;" })
    );
  } catch (err) {
    console.error(err);
  }

  // const { head, body, foot } = await getGridContent(gridElement);
  // const content = [...head, ...body, ...foot]
  //   .map((cells) => cells.map(serialiseCellValue).join(","))
  //   .join("\n");

  // downloadFile(
  //   fileName,
  //   new Blob([content], { type: "text/csv;charset=utf-8;" })
  // );
}

import {
  Grid,
  GridItem,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { BsCalendarDay } from "react-icons/bs";
import DaysOfWeekSelect from "./DaysOfWeekSelect";

export default function DaysOfWeekControl() {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton icon={<BsCalendarDay />} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <Grid templateRows="repeat(1, 1fr)" templateColumns="repeat(6, 1fr)">
          <GridItem colSpan={6}>
            <DaysOfWeekSelect />
          </GridItem>
        </Grid>
      </PopoverContent>
    </Popover>
  );
}

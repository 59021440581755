import * as React from "react";
import Select from "react-select";

import { useQuery, gql } from "@apollo/client";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  CurrentBusiness,
  FormControlSelector,
  StudentReportControls,
} from "../../../../atoms";
import SearchPopup from "../../../../components/SearchPopup/SearchPopup";

const GET_FORMS = gql`
  query Forms($businessId: Int!) {
    forms(businessId: $businessId) {
      id
      formName
      formDescription
      businessId
      updatedAt
      createdAt
      active
      formFields {
        id
        fieldNumber
        field {
          id
          fieldValue
          fieldType
          fieldName
          fieldFormatting
          createdAt
          updatedAt
          active
          businessId
        }
        active
        createdAt
        formId
        required
        updatedAt
      }
    }
  }
`;

export default function FormsControl() {
  const [controls, setControls] = useRecoilState(StudentReportControls);
  const [business] = useRecoilState(CurrentBusiness);
  const selectedOptions = useRecoilValue(FormControlSelector);

  const [options, setOptions] = React.useState([]);
  function onCompleted(data) {
    const arr = data.forms.map((form) => ({
      label: form.formName,
      key: `custom-${form.id}`,
      options: form.formFields.map((formField) => ({
        key: parseInt(formField.id),
        formName: form.formName,
        name: formField.field.fieldName,
      })),
    }));
    setOptions(arr);
  }
  // console.log("Controls: ", controls);

  const businessId = business.id;
  const { loading, error } = useQuery(GET_FORMS, {
    variables: { businessId },
    skip: isNaN(businessId),
    onCompleted,
  });

  function handleChange(option, checked) {
    console.log("================ OPTION: ", option);
    console.log("CHECKED: ", checked);
    console.log(controls);
    if (checked) {
      setControls({
        ...controls,
        columns: [...controls.columns, option],
      });
    } else {
      console.log(
        "WITHOUT: ",
        controls.columns.filter((value) => value.key !== option.key)
      );
      setControls({
        ...controls,
        columns: controls.columns.filter((value) => value.key !== option.key),
      });
    }
  }
  console.log(options);
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div>
      <SearchPopup
        onChange={handleChange}
        // key={Math.random()}
        placeholder="Custom Forms"
        options={options}
        selected={selectedOptions}
        grouped={true}
      />
      {/* <Select
        placeholder="Custom Forms"
        value={selectedOptions}
        isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.key}
        onChange={handleChange}
        grouped={false}
      /> */}
      {loading && <div>Loading</div>}
      {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Autocomplete
          loading={loading}
          loadingText="Loading"
          limitTags={2}
          size="small"
          multiple
          id="checkboxes-tags-demo"
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          groupBy={(option) => option.formName}
          value={selectedOptions}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.key}>
                {option.name}
              </li>
            );
          }}
          onChange={(event, newValue) => {
            console.log("NEW VALUE: ", newValue);
            // handleChange(e);
            handleChange(event, newValue);
          }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Custom Forms"
              placeholder="Custom Forms"
            />
          )}
        />
      </FormControl> */}
    </div>
  );
}

import * as React from "react";
import Select from "react-select";

import { useRecoilState, useRecoilValue } from "recoil";

import {
  StudentReportDaysOfWeekOptions,
  StudentReportControls,
  DaysOfWeekControlSelector,
  accountInfoArray,
  studentInfoArray,
} from "../../../../atoms";

export default function DaysOfWeekSelect() {
  const [controls, setControls] = useRecoilState(StudentReportControls);
  const selectedOptions = useRecoilValue(DaysOfWeekControlSelector);
  const [options] = useRecoilState(StudentReportDaysOfWeekOptions);

  // console.log("OPTIONS: ", selectedOptions);
  function handleChange(newValue) {
    let newColumns = [];

    newValue.forEach((val) => {
      if (
        controls.columns.findIndex((column) => column.key === val.key) === -1
      ) {
        // Need to add a value
        newColumns = [...controls.columns, val];
        console.log("ADDING THE VALUE!!!!!!");
      }
    });
    if (newColumns.length === 0) {
      console.log("LOOKS LIKE WE NEED TO REMOVE THE VALUE");
      const allAccountKeys = [...accountInfoArray, studentInfoArray];
      controls.columns.forEach((column) => {
        console.log(
          "KEY: ",
          column.key,
          " IS NUMBER: ",
          typeof column.key === "number",
          " IS ACCOUNT FIELD: ",
          allAccountKeys.indexOf(column.key) > -1
        );
        if (
          typeof column.key === "number" ||
          allAccountKeys.indexOf(column.key) > -1 ||
          newValue.findIndex((val) => val.key === column.key) > -1
        ) {
          newColumns = [...newColumns, column];
        }
      });
    }
    setControls({ ...controls, columns: newColumns });
  }
  return (
    <div>
      <Select
        value={selectedOptions}
        placeholder="Day of Week"
        isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.key}
        onChange={handleChange}
        closeMenuOnSelect={false}
      />
    </div>
  );
}

import * as React from "react";
import { useQuery, gql } from "@apollo/client";
import { useRecoilState } from "recoil";
import { CurrentBusiness, StudentReportControls } from "../../../../atoms";
import SearchPopup from "../../../../components/SearchPopup";
import { Spinner } from "@chakra-ui/react";

const GET_LOCATIONS = gql`
  query Locations($businessId: Int) {
    locations(businessId: $businessId) {
      active
      businessId
      createdAt
      id
      locationCity
      locationAddress2
      locationAddress
      locationName
      locationPhone
      locationState
      locationStatus
      locationZip
      updatedAt
    }
  }
`;

export default function LocationsControl() {
  const [controls, setControls] = useRecoilState(StudentReportControls);
  const [business] = useRecoilState(CurrentBusiness);

  const [options, setOptions] = React.useState([]);

  function onCompleted(data) {
    const arr = data.locations.map((val) => ({
      key: parseInt(val.id),
      name: val.locationName,
    }));
    setOptions(arr);
  }

  const { filters } = controls;
  const businessId = business.id;
  const { loading, error } = useQuery(GET_LOCATIONS, {
    variables: { businessId },
    skip: isNaN(businessId),
    onCompleted,
  });

  function handleChange(option, checked) {
    console.log("OPTION: ", option, " Checked: ", checked);
    if (checked) {
      setControls({
        ...controls,
        filters: {
          ...filters,
          locations: [...filters.locations, option],
        },
      });
    } else {
      setControls({
        ...controls,
        filters: {
          ...filters,
          locations: [
            ...filters.locations.filter((value) => value.key !== option.key),
          ],
        },
      });
    }
  }

  if (error) return <p>Error : {error.message}</p>;

  return (
    <>
      <SearchPopup
        onChange={handleChange}
        // key={Math.random()}
        placeholder="Locations"
        options={options}
        selected={filters.locations}
      />
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
}

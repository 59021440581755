import {
  Grid,
  GridItem,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { BsFilter } from "react-icons/bs";
import BetweenDates from "./BetweenDates";
import CategoriesControl from "./CategoriesControl";
import EventProgramsControl from "./EventProgramsControl";
import LocationsControl from "./LocationsControl";
import SingleDate from "./SingleDate";

export default function FilterDropdown() {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton icon={<BsFilter />} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <Grid templateRows="repeat(4, 1fr)" templateColumns="repeat(6, 1fr)">
          <GridItem colSpan={3}>
            <SingleDate />
          </GridItem>
          <GridItem colSpan={3}>
            <BetweenDates />
          </GridItem>
          <GridItem colSpan={6}>
            <EventProgramsControl />
            {/* <SearchPopup /> */}
            {/* <EventProgramsControl />{" "} */}
          </GridItem>
          <GridItem colSpan={6}>
            <CategoriesControl />
          </GridItem>
          <GridItem colSpan={6}>
            <LocationsControl />
          </GridItem>
        </Grid>
      </PopoverContent>
    </Popover>
  );
}

import * as React from "react";
import { useQuery, gql } from "@apollo/client";
import { useRecoilState } from "recoil";
import { Spinner } from "@chakra-ui/react";
import { CurrentBusiness, StudentReportControls } from "../../../../atoms";
import SearchPopup from "../../../../components/SearchPopup";

const GET_CATEGORIES = gql`
  query Categories(
    $businessId: Int
    $date: Date
    $startDate: Date
    $endDate: Date
  ) {
    categories(
      businessId: $businessId
      date: $date
      startDate: $startDate
      endDate: $endDate
    ) {
      active
      businessId
      categoryName
      createdAt
      id
      order
      updatedAt
    }
  }
`;

export default function CategoriesControl() {
  const [controls, setControls] = useRecoilState(StudentReportControls);
  const [business] = useRecoilState(CurrentBusiness);
  const [options, setOptions] = React.useState([]);

  const { filters } = controls;
  const { date, startDate, endDate } = filters;

  function onCompleted(data) {
    // console.log("DATYA: ", data);
    const arr = data.categories.map((val) => ({
      key: parseInt(val.id),
      name: val.categoryName,
    }));
    setOptions(arr);
  }

  const businessId = business.id;
  const { loading, error } = useQuery(GET_CATEGORIES, {
    variables: { businessId, date, startDate, endDate },
    skip: isNaN(businessId),
    onCompleted,
  });

  // console.error(error);
  if (error) return <p>Error : {error.message}</p>;

  function handleChange(option, checked) {
    console.log("OPTION: ", option, " Checked: ", checked);
    if (checked) {
      setControls({
        ...controls,
        filters: {
          ...filters,
          categories: [...filters.categories, option],
        },
      });
    } else {
      setControls({
        ...controls,
        filters: {
          ...filters,
          categories: [
            ...filters.categories.filter((value) => value.key !== option.key),
          ],
        },
      });
    }
  }

  return (
    <>
      <SearchPopup
        onChange={handleChange}
        // key={Math.random()}
        placeholder="Categories"
        options={options}
        selected={filters.categories}
      />
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
}

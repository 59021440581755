import { useMemo, useState } from "react";

import DataGrid from "react-data-grid";
import { Spinner } from "@chakra-ui/react";
import "react-data-grid/lib/styles.css";
import "./CRDataGrid.css";

function getComparator(sortColumn) {
  console.log("RUNNING WITH: ", sortColumn);
  switch (sortColumn) {
    case "firstName":
    case "lastName":
    case "client":
    case "area":
    case "country":
    case "contact":
    case "transaction":
    case "account":
    case "version":
      return (a, b) => {
        return a[sortColumn].localeCompare(b[sortColumn]);
      };
    case "available":
      return (a, b) => {
        return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      };
    case "id":
    case "progress":
    case "startTimestamp":
    case "endTimestamp":
    case "budget":
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

export default function CRDataGrid({ data, loading, columns }) {
  const [sortColumns, setSortColumns] = useState([]);
  // return <div>Fuck off</div>;
  function EmptyRowsRenderer() {
    if (loading) {
      return (
        // <div
        //   style={{
        //     textAlign: "center",
        //     marginTop: "80px",
        //     gridColumn: "1/-1",
        //   }}
        // >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        >
          <Spinner />
        </div>
        // </div>
      );
    }

    return (
      <div style={{ textAlign: "center", gridColumn: "1/-1" }}>
        Nothing to show{" "}
        <span lang="ja" title="ショボーン">
          (´・ω・`)
        </span>
      </div>
    );
  }

  const dataColumns = useMemo(() => {
    return columns.map((column, i) => {
      if (i === columns.length - 2) {
        return {
          ...column,
          summaryFormatter() {
            return <strong>Total</strong>;
          },
        };
      } else if (i === columns.length - 1) {
        return {
          ...column,
          summaryFormatter({ row }) {
            return <>{row.totalCount} records</>;
          },
        };
      }
      return { ...column };
    });
  }, [columns]);

  const summaryRows = useMemo(() => {
    const summaryRow = {
      id: "total_0",
      totalCount: data.length,
      // yesCount: data.filter((r) => r.available).length,
      yesCount: 1010,
    };
    return [summaryRow];
  }, [data]);

  const sortedRows = useMemo(() => {
    if (loading) return [];
    if (sortColumns.length === 0) return data;
    // console.log("DATA: ", data);
    return [...data].sort((a, b) => {
      for (const sort of sortColumns) {
        console.log("SORT: ", sort);
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [data, loading, sortColumns]);
  // console.log("DATA COLS: ", dataColumns);
  return (
    <DataGrid
      columns={dataColumns}
      defaultColumnOptions={{
        sortable: true,
        resizable: true,
      }}
      renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
      sortColumns={sortColumns}
      onSortColumnsChange={setSortColumns}
      rows={sortedRows}
      rowHeight={22}
      className="fill-grid"
      bottomSummaryRows={summaryRows}
    />
  );
}

import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Heading,
  Input,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  VStack,
} from "@chakra-ui/react";

import "./SearchPopup.css";
import OutsideAlerter from "../OutsideAlerter";

function GroupedItems(props) {
  console.log("PROPS GROUPED: ", props);
  // (e) => props.onChange(option, e.target.checked)
  return (
    <>
      <Heading as="h5" size="sm">
        {props.label}
      </Heading>
      {props.options.map((option) => (
        <SingleItem
          key={`grouped-option-key-${option.key}`}
          {...props}
          option={option}
        />
      ))}
    </>
  );
}

function SingleItem(props) {
  // console.log("PROPS OPTION: ", props.option);
  const selected =
    props.selected.findIndex((select) => select.key === props.option.key) > -1;
  // console.log("PROPS for single", props, " IS SELECTED: ", selected);
  return (
    <ListItem className="checklist" key={props.option.key}>
      <Checkbox
        isChecked={selected}
        onChange={(e) => props.onChange(props.option, e.target.checked)}
      >
        {props.option.name}
      </Checkbox>
    </ListItem>
  );
}

export default function SearchPopup(props) {
  const [filter, setFilter] = React.useState("");
  const [isOpen, setOpen] = React.useState(false);
  const firstFieldRef = React.useRef(null);
  const { selected } = props || [];
  console.log("SELECTED: ", selected);
  console.log("PROPS: ", props);

  function handleChange(e) {
    console.log("E: ", e.target.value);
    setFilter(e.target.value);
  }

  function filterOptions() {
    if (props.grouped) {
      let results = [];
      props.options.forEach((group) => {
        const options = group.options.filter((option) => {
          // console.log(option.name.toLowerCase());
          // console.log("FILTER: ", filter, " OPTION NAME: ", option.name);
          return option.name
            .toLowerCase()
            .startsWith(filter.trim().toLocaleLowerCase());
        });
        console.log("FINISHING WITH OPTIONS: ", options);
        if (options.length > 0) {
          console.log(" THESE OPTIONS HANVE LENGTH ", options);
          results = [...results, { ...group, options }];
          console.log("RESULTS: ", results);
        }
      });
      console.log("RESULTS: ", results);
      return results;
    }
    return props.options.filter((option) => {
      console.log(option.name.toLowerCase());
      return option.name
        .toLowerCase()
        .startsWith(filter.trim().toLocaleLowerCase());
    });
  }

  const filteredOptions =
    filter.trim().length > 0 ? filterOptions() : props.options;
  return (
    <OutsideAlerter handleClick={() => setOpen(false)}>
      <Popover
        isLazy={true}
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        // onOpen={onOpen}
        // onClose={onClose}
        placement="right"
        closeOnBlur={false}
      >
        <PopoverAnchor>
          <Button onClick={() => setOpen(!isOpen)} variant="ghost">
            {props.placeholder}
          </Button>
        </PopoverAnchor>
        <PopoverContent>
          <PopoverBody>
            <VStack spacing={4} align="stretch">
              <Box>
                <PopoverArrow />
                <PopoverCloseButton onClick={() => setOpen(false)} />
              </Box>
              <Box>
                <Input
                  // onBlur={(e) => e.stopPropagation()}
                  placeholder={props.placeholder || "Search"}
                  onChange={handleChange}
                />
              </Box>
              <Box className="search-popup-menu-box">
                <List
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                  spacing={3}
                >
                  {props.grouped &&
                    filteredOptions.map((option) => (
                      <GroupedItems
                        selected={selected}
                        key={option.key}
                        options={option.options}
                        label={option.label}
                        onChange={(item, checked) =>
                          props.onChange(item, checked)
                        }
                      />
                    ))}
                  {!props.grouped &&
                    filteredOptions.map((option) => (
                      <SingleItem selected={selected} key={option.key} />
                    ))}
                </List>

                {!props.grouped && (
                  <List
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                    spacing={3}
                  >
                    {filteredOptions.map((option) => (
                      <ListItem className="checklist" key={option.key}>
                        <Checkbox
                          isChecked={
                            selected.findIndex(
                              (select) => select.key === option.key
                            ) > -1
                          }
                          onChange={(e) =>
                            props.onChange(option, e.target.checked)
                          }
                        >
                          {option.name}
                        </Checkbox>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </OutsideAlerter>
  );
}

export default function transactionBlocksHelper(studentEvents, readField) {
  let blockMap = {};
  studentEvents.forEach((studentEvent) => {
    const event = readField("event", studentEvent);
    const eventId = readField("id", event);

    blockMap[eventId] = [];
    // console.log("block map: ", blockMap);
    const transaction = readField("transaction", studentEvent);
    if (!transaction) {
      return blockMap;
    }
    const transactionBlocks = readField("transactionBlocks", transaction);
    if (!transactionBlocks) {
      return blockMap;
    }
    // console.log(transactionBlocks);
    transactionBlocks.forEach((transactionBlock) => {
      const block = readField("block", transactionBlock);
      blockMap[eventId].push(block);
    });
    // blockMap[studentEvent.]
    // console.log("STUDENT EVENT: ", studentEvent);
    return blockMap;
  });
  return blockMap;
}

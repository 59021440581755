import * as React from "react";
import { useQuery, gql } from "@apollo/client";

import { useRecoilState } from "recoil";
import { CurrentBusiness, StudentReportControls } from "../../../../atoms";

import SearchPopup from "../../../../components/SearchPopup";
import { Spinner } from "@chakra-ui/react";

const GET_EVENT_PROGRAMS = gql`
  query EventPrograms(
    $businessId: Int!
    $date: Date
    $startDate: Date
    $endDate: Date
  ) {
    eventPrograms(
      businessId: $businessId
      date: $date
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      programName
      enrollType
      enrollRange
      enrollActive
      description
      createdAt
      category {
        id
        categoryName
        businessId
        createdAt
        updatedAt
        order
        active
      }
      capacity
      businessId
      billingActive
      active
      categoryId
      lateEnrollFee
      lateEnrollRange
      maxAge
      minAge
      price
      prorate
      updatedAt
      waitlist
    }
  }
`;

export default function EventProgramsControl() {
  const [controls, setControls] = useRecoilState(StudentReportControls);
  const [business] = useRecoilState(CurrentBusiness);
  const [options, setOptions] = React.useState([]);

  const { filters } = controls;
  const { date, startDate, endDate } = filters;

  function onCompleted(data) {
    // console.log("DATA for event programs: ", data);
    const arr = data.eventPrograms.map((val) => ({
      key: parseInt(val.id),
      name: val.programName,
    }));
    setOptions(arr);
  }

  // console.log(" WE HAVE DATE ", date);

  const businessId = business.id;
  const { loading, error } = useQuery(GET_EVENT_PROGRAMS, {
    variables: { businessId, date, startDate, endDate },
    skip: isNaN(businessId),
    onCompleted,
  });

  function handleChange(option, checked) {
    if (checked) {
      setControls({
        ...controls,
        filters: {
          ...filters,
          eventPrograms: [...filters.eventPrograms, option],
        },
      });
    } else {
      setControls({
        ...controls,
        filters: {
          ...filters,
          eventPrograms: [
            ...filters.eventPrograms.filter(
              (value) => value.key !== option.key
            ),
          ],
        },
      });
    }
  }

  if (error) return <p>Error : {error.message}</p>;
  return (
    <>
      <SearchPopup
        onChange={handleChange}
        // key={Math.random()}
        placeholder="Programs"
        options={options}
        selected={filters.eventPrograms}
      />
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
}

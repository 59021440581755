import Dashboard from "./components/Dashboard";

import Students from "./pages/students/Students";
function App() {
  return (
    <div className="App">
      <Dashboard>
        <Students />
      </Dashboard>
    </div>
  );
}

export default App;

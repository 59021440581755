import Parse from "parse";

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID);
//javascriptKey is required only if you have it on server.

const host =
  process.env.REACT_APP_HOST_API || "https://communityroot.herokuapp.com";

Parse.serverURL = `${host}/parse`;

export default Parse;

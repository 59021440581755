// Import everything needed to use the `useQuery` hook
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { BsDownload } from "react-icons/bs";

import {
  CurrentBusiness,
  DaysOfWeekControlFilterSelector,
  FormControlFilterSelector,
  StudentExportDataSelector,
  StudentReportControls,
  StudentReportData,
  StudentReportDataSelector,
  StudentReportFilterCategorySelector,
  StudentReportFilterEventProgramSelector,
  StudentReportFilterLocationSelector,
} from "../../atoms";

import CRDataGrid from "../../components/CRDataGrid";
import DaysOfWeekControl from "./Controls/DaysOfWeekControl";

import { exportToCsv } from "../../utils/exportUtils";

import { IconButton, Box, Flex, Spacer } from "@chakra-ui/react";
import FilterControl from "./Controls/FilterControl";
import ColumnControl from "./Controls/ColumnControl";
import GET_STUDENT_REPORT from "./query";

function StudentsReport() {
  const setStudentData = useSetRecoilState(StudentReportData);
  const [business] = useRecoilState(CurrentBusiness);
  // console.log("BUSINESS: ==================================== ", business);
  const results = useRecoilValue(StudentReportDataSelector);

  const [controls] = useRecoilState(StudentReportControls);

  const { columns, filters } = controls;
  const { date, startDate, endDate } = filters;
  // console.log("COLUMNS: ", columns);

  function onCompleted(data) {
    // console.log("COLUMNS : ", columns);
    setStudentData(data.studentReport);
  }

  const eventPrograms = useRecoilValue(StudentReportFilterEventProgramSelector);
  const locations = useRecoilValue(StudentReportFilterLocationSelector);
  const categories = useRecoilValue(StudentReportFilterCategorySelector);
  const formFields = useRecoilValue(FormControlFilterSelector);
  const daysOfWeek = useRecoilValue(DaysOfWeekControlFilterSelector);
  const businessId = business.id;
  // console.log(" BUSINESS ID: ", businessId);

  const { loading, error } = useQuery(GET_STUDENT_REPORT, {
    variables: {
      businessId,
      eventPrograms,
      locations,
      categories,
      date,
      startDate,
      endDate,
      formFields,
      daysOfWeek,
    },
    skip: isNaN(businessId),
    onCompleted,
  });
  if (error) return <p>Error : {error.message}</p>;
  // console.log(" NO ERROR SHOW TABLE ");
  return <CRDataGrid loading={loading} data={results} columns={columns} />;
}

export default function Students() {
  const [exporting, setExporting] = useState(false);
  const results = useRecoilValue(StudentExportDataSelector);
  return (
    <div>
      <Box w="100%" p={2}>
        <Flex minWidth="max-content" alignItems="center" gap="2">
          <Box p="2">
            <ColumnControl />
          </Box>
          <Box p="2">
            <DaysOfWeekControl />
          </Box>
          <Box p="2">
            <FilterControl />
          </Box>
          <Spacer />
          <Box gap="2">
            <IconButton
              icon={<BsDownload />}
              disabled={exporting}
              onClick={async () => {
                setExporting(true);
                await exportToCsv(results, "students.csv");
                setExporting(false);
              }}
            />
          </Box>
        </Flex>
      </Box>
      <StudentsReport />
    </div>
  );
}

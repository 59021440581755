import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CurrentBusiness, CurrentUser } from "../atoms";
import Parse from "../parse";

export default function Protected({ children }) {
  const currentUser = Parse.User.current();
  const setUser = useSetRecoilState(CurrentUser);
  const setBusiness = useSetRecoilState(CurrentBusiness);

  // console.log(currentUser);
  // console.log(children);
  const location = useLocation();

  async function syncBusiness() {
    const business = await Parse.Cloud.run("syncUserAccount");
    setBusiness(business);
  }

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
      syncBusiness();
    }
  });

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

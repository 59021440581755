import * as React from "react";

import { useRecoilState, useRecoilValue } from "recoil";
import Select from "react-select";

import {
  StudentReportAccountInfoOptions,
  StudentReportControls,
  AccountInfoControlSelector,
  daysOfWeekArray,
} from "../../../../atoms";

export default function AccountInfoControl() {
  const [controls, setControls] = useRecoilState(StudentReportControls);
  const selectedOptions = useRecoilValue(AccountInfoControlSelector);
  const [options] = useRecoilState(StudentReportAccountInfoOptions);

  // console.log("OPTIONS: ", selectedOptions);
  function handleChange(newValue) {
    let newColumns = [];
    newValue.forEach((val) => {
      // console.log("VAL: ", val);
      if (
        controls.columns.findIndex((column) => column.key === val.key) === -1
      ) {
        // Need to add a value
        newColumns = [...controls.columns, val];
      }
    });
    if (newColumns.length === 0) {
      controls.columns.forEach((column) => {
        if (
          typeof column.key === "number" ||
          daysOfWeekArray.findIndex((val) => val.key === column.key) > -1 ||
          newValue.findIndex((val) => val.key === column.key) > -1
        ) {
          newColumns = [...newColumns, column];
        }
      });
    }
    setControls({ ...controls, columns: newColumns });
  }
  return (
    <div>
      <Select
        placeholder="Student Accounts"
        value={selectedOptions}
        isMulti
        name="colors"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.key}
        onChange={handleChange}
        closeMenuOnSelect={false}
      />
      {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Autocomplete
          size="small"
          limitTags={2}
          loadingText="Loading"
          multiple
          id="checkboxes-tags-demo"
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          groupBy={(option) => option.formName}
          value={selectedOptions}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.key}>
                {option.name}
              </li>
            );
          }}
          onChange={(_event, newValue) => {
            console.log("NEW VALUE: ", newValue);
            // handleChange(e);
            handleChange(newValue);
          }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Custom AccountInfo"
              placeholder="Custom AccountInfo"
            />
          )}
        />
      </FormControl> */}
    </div>
  );
}

import {
  Grid,
  GridItem,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { BsFileSpreadsheet } from "react-icons/bs";
import AccountInfoControl from "./AccountInfoControl";
import FormsControl from "./FormsControl";

export default function ColumnControl() {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton icon={<BsFileSpreadsheet />} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Confirmation!</PopoverHeader>
        <Grid>
          <GridItem>
            <AccountInfoControl />
          </GridItem>
          <GridItem>
            <FormsControl />
          </GridItem>
        </Grid>
      </PopoverContent>
    </Popover>
  );
}

import { ApolloClient, InMemoryCache } from "@apollo/client";
import transactionBlocksHelper from "../utils/transactionBlocksHelper";

const cache = new InMemoryCache({
  typePolicies: {
    Student: {
      fields: {
        accountEmail: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            // return "HI";
            // return null;
            const user = readField("user", account);
            if (!user) {
              return null;
            }
            const value = readField("email", user);
            return value;
          },
        },
        accountName: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("name", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountFirstName: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("firstName", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountLastName: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("lastName", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountCity: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("city", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountAddress: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("address", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountAddress2: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("address2", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountState: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("state", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountZip: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("zip", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        accountPhone: {
          // read(_, { readField }) {
          read(_, { readField }) {
            const account = readField("account");
            if (!account) {
              return null;
            }
            const value = readField("phone", account);
            // console.log("VALUE: ", value);
            return value;
          },
        },
        blocks: {
          // read(_, { readField }) {
          read(_, { readField }) {
            let attending = false;
            const studentEvents = readField("studentEvents");
            if (studentEvents) {
              const blocks = transactionBlocksHelper(studentEvents, readField);
              // console.log("BLOCKS: ", blocks);
              return blocks;
            }
            // const studentSchedule = readField("studentSchedule", studentEvents);
            // const value = readField("name", account);
            // return value;
            return attending;
          },
        },
        monday: {
          read(_, { readField }) {
            const blocks = readField("blocks");
            let isAttending = false;
            if (blocks) {
              Object.keys(blocks).forEach((eventId) => {
                if (blocks[eventId].indexOf(1) > -1) {
                  isAttending = true;
                }
              });
            }
            return `${isAttending}`;
          },
        },
        tuesday: {
          read(_, { readField }) {
            const blocks = readField("blocks");
            let isAttending = false;
            if (blocks) {
              Object.keys(blocks).forEach((eventId) => {
                if (blocks[eventId].indexOf(2) > -1) {
                  isAttending = true;
                }
              });
            }
            return `${isAttending}`;
          },
        },
        wednesday: {
          read(_, { readField }) {
            const blocks = readField("blocks");
            let isAttending = false;
            if (blocks) {
              Object.keys(blocks).forEach((eventId) => {
                if (blocks[eventId].indexOf(3) > -1) {
                  isAttending = true;
                }
              });
            }
            return `${isAttending}`;
          },
        },
        thursday: {
          read(_, { readField }) {
            const blocks = readField("blocks");
            let isAttending = false;
            if (blocks) {
              Object.keys(blocks).forEach((eventId) => {
                if (blocks[eventId].indexOf(4) > -1) {
                  isAttending = true;
                }
              });
            }
            return `${isAttending}`;
          },
        },
        friday: {
          read(_, { readField }) {
            const blocks = readField("blocks");
            let isAttending = false;
            if (blocks) {
              Object.keys(blocks).forEach((eventId) => {
                if (blocks[eventId].indexOf(5) > -1) {
                  isAttending = true;
                }
              });
            }
            return `${isAttending}`;
          },
        },
        saturday: {
          read(_, { readField }) {
            const blocks = readField("blocks");
            let isAttending = false;
            if (blocks) {
              Object.keys(blocks).forEach((eventId) => {
                if (blocks[eventId].indexOf(6) > -1) {
                  isAttending = true;
                }
              });
            }
            return `${isAttending}`;
          },
        },
        sunday: {
          read(_, { readField }) {
            const blocks = readField("blocks");
            let isAttending = false;
            if (blocks) {
              Object.keys(blocks).forEach((eventId) => {
                if (blocks[eventId].indexOf(7) > -1) {
                  isAttending = true;
                }
              });
            }
            return `${isAttending}`;
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  uri: "http://localhost:8080/graphql",
  cache,
});

export default client;

// client
//   .query({
//     query: gql`
//       query ExampleQuery($transactionId: ID!) {
//         transaction(id: $transactionId) {
//           id
//         }
//       }
//     `,
//     variables: {
//       transactionId: 1,
//     },
//   })
//   .then((result) => console.log(result));

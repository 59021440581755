import { gql } from "@apollo/client";

const GET_STUDENT_REPORT = gql`
  query StudentReport(
    $businessId: Int!
    $eventPrograms: [Int]
    $locations: [Int]
    $categories: [Int]
    $formFields: [Int]
    $date: Date
    $startDate: Date
    $endDate: Date
    $daysOfWeek: [String]
  ) {
    studentReport(
      businessId: $businessId
      eventPrograms: $eventPrograms
      locations: $locations
      categories: $categories
      formFields: $formFields
      date: $date
      startDate: $startDate
      endDate: $endDate
      daysOfWeek: $daysOfWeek
    ) {
      accountName @client
      accountFirstName @client
      accountLastName @client
      accountEmail @client
      accountAddress @client
      accountAddress2 @client
      accountCity @client
      accountState @client
      accountZip @client
      accountPhone @client
      monday @client
      tuesday @client
      wednesday @client
      thursday @client
      friday @client
      saturday @client
      sunday @client
      dob
      grade
      firstName
      customData
      createdAt
      active
      accountId
      account {
        active
        address
        address2
        businessId
        city
        state
        zip
        phone
        createdAt
        firstName
        id
        lastName
        name
        user {
          email
          id
          createdAt
        }
      }
      id
      lastName
      name
      studentEvents {
        active
        amount
        blocks
        categoryName
        choiceAmount
        choiceId
        choices
        createdAt
        event {
          active
          createdAt
          eventProgram {
            active
            billingActive
            capacity
            category {
              active
              businessId
              categoryName
              createdAt
              order
              id
              updatedAt
            }
            businessId
            createdAt
            description
            enrollActive
            enrollRange
            enrollType
            programName
            id
            lateEnrollFee
            lateEnrollRange
            maxAge
            minAge
            price
            programId
            prorate
            updatedAt
            waitlist
          }
          id
          locationId
          recurringBillingStatus
          recurringProrate
          updatedAt
        }
        businessId
        fullDays
        id
        locationName
        programName
        studentId
        studentSchedule {
          active
          blocks
          createdAt
          endDate
          eventId
          id
          startDate
          studentId
          updatedAt
          transactionId
        }
        totalCost
        transaction {
          accountId
          active
          businessId
          createdAt
          id
          payments {
            accountId
            active
            createdAt
            id
            updatedAt
            amount
            businessId
            canceledAt
            canceledById
            canceledNotes
            convenienceFeeAmount
            ledgerAmount
            method
            notes
            refundPaymentId
            source
            sourceId
            transactionId
            userId
          }
          transactionBlocks {
            active
            block
            blockFee
            choiceFee
            createdAt
            eventId
            id
            studentId
            transactionId
            updatedAt
            waitlist
          }
          updatedAt
        }
      }
      updatedAt
    }
  }
`;

export default GET_STUDENT_REPORT;

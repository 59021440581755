import Parse from "../parse";

import { atom, selector } from "recoil";

const studentInfoOptions = [
  {
    formName: "Student Info",
    key: "id",
    name: "Student Id",
    default: true,
  },
  {
    formName: "Student Info",
    key: "name",
    name: "Student Name",
    default: true,
  },
  {
    formName: "Student Info",
    key: "firstName",
    name: "Student First Name",
  },
  {
    formName: "Student Info",
    key: "lastName",
    name: "Student Last Name",
  },
  {
    formName: "Student Info",
    key: "dob",
    name: "Student DOB",
    default: true,
  },
  {
    formName: "Student Info",
    key: "grade",
    name: "Student Grade",
  },
  {
    formName: "Student Info",
    key: "createdAt",
    name: "Student Registration Date",
  },
];

const accountOptions = [
  {
    formName: "Account Info",
    key: "accountId",
    name: "Account Id",
    default: true,
  },
  {
    formName: "Account Info",
    key: "accountEmail",
    name: "Account Email",
    default: true,
  },
  {
    formName: "Account Info",
    key: "accountName",
    name: "Account Name",
    default: true,
  },
  {
    formName: "Account Info",
    key: "accountFirstName",
    name: "Account First Name",
  },
  {
    formName: "Account Info",
    key: "accountLastName",
    name: "Account Last Name",
  },
  {
    formName: "Account Info",
    key: "accountPhone",
    name: "Account Phone",
    default: true,
  },
  {
    formName: "Account Info",
    key: "accountAddress",
    name: "Account Address",
  },
  {
    formName: "Account Info",
    key: "accountAddress2",
    name: "Account Address 2",
  },
  {
    formName: "Account Info",
    key: "accountZip",
    name: "Account Zip Code",
  },
  {
    formName: "Account Info",
    key: "accountCity",
    name: "Account City",
  },
  {
    formName: "Account Info",
    key: "accountState",
    name: "Account State",
  },
  {
    formName: "Account Info",
    key: "accountCreatedAt",
    name: "Account Registration Date",
  },
];

const daysOfWeekOptions = [
  {
    key: "monday",
    name: "Monday",
  },
  {
    key: "tuesday",
    name: "Tuesday",
  },
  {
    key: "wednesday",
    name: "Wednesday",
  },
  {
    key: "thursday",
    name: "Thursday",
  },
  {
    key: "friday",
    name: "Friday",
  },
  {
    key: "saturday",
    name: "Saturday",
  },
  {
    key: "sunday",
    name: "Sunday",
  },
];

export const daysOfWeekArray = daysOfWeekOptions.map(
  (daysOfWeekOption) => daysOfWeekOption.key
);
export const accountInfoArray = accountOptions.map(
  (accountOption) => accountOption.key
);

export const studentInfoArray = studentInfoOptions.map((option) => option.key);

const defaultColumns = [...studentInfoOptions, ...accountOptions].filter(
  (option) => option.default
);

export const CurrentUser = atom({
  key: "CurrentUser", // unique ID (with respect to other atoms/selectors)
  default: Parse.User.current(),
});

export const CurrentBusiness = atom({
  key: "CurrentBusiness", // unique ID (with respect to other atoms/selectors)
  default: {},
});

export const StudentReportStudentInfoOptions = atom({
  key: "StudentReportStudentInfoOptions", // unique ID (with respect to other atoms/selectors)
  default: accountOptions,
});

export const StudentReportData = atom({
  key: "StudentReportData", // unique ID (with respect to other atoms/selectors)
  default: [],
});

export const StudentReportAccountInfoOptions = atom({
  key: "StudentReportAccountInfoOptions", // unique ID (with respect to other atoms/selectors)
  default: [
    { label: "Student Info", options: studentInfoOptions, key: "Student Info" },
    { label: "Account Info", options: accountOptions, key: "Account Info" },
  ],
});

export const StudentReportDaysOfWeekOptions = atom({
  key: "StudentReportDaysOfWeekOptions", // unique ID (with respect to other atoms/selectors)
  default: daysOfWeekOptions,
});

export const StudentReportControls = atom({
  key: "StudentReportControls", // unique ID (with respect to other atoms/selectors)
  default: {
    columns: defaultColumns,
    filters: {
      eventPrograms: [],
      locations: [],
      categories: [],
      date: "",
      startDate: "",
      endDate: "",
    },
  }, // default value (aka initial value)
});

export const StudentReportFilterEventProgramSelector = selector({
  key: "StudentReportEventProgramSelector", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.filters.eventPrograms.map((o) => o.key);
  },
});

export const StudentReportDataSelector = selector({
  key: "StudentReportDataSelector", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const studentReportData = get(StudentReportData);
    const { columns } = get(StudentReportControls);
    return studentReportData.map((student) => {
      const obj = {
        ...student,
        ...student.customData,
      };
      // console.log("OBJ: ", obj);
      // console.log(columns);
      const result = {};
      columns.forEach((col) => (result[col.key] = obj[col.key]));
      // console.log("RESULT: ", result);
      return result;
    });
  },
});

export const StudentExportDataSelector = selector({
  key: "StudentExportDataSelector", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const studentReportData = get(StudentReportData);
    const { columns } = get(StudentReportControls);
    return studentReportData.map((student) => {
      const obj = {
        ...student,
        ...student.customData,
      };
      // console.log("OBJ: ", obj);
      // console.log(columns);
      const result = {};
      columns.forEach((col) => (result[col.name] = obj[col.key]));
      // console.log("RESULT: ", result);
      return result;
    });
  },
});

export const StudentReportFilterLocationSelector = selector({
  key: "StudentReportLocationSelector", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.filters.locations.map((o) => o.key);
  },
});
export const StudentReportFilterCategorySelector = selector({
  key: "StudentReportCategorySelector", // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.filters.categories.map((o) => o.key);
  },
});
export const FormControlFilterSelector = selector({
  key: "FormControlFilterSelector",
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.columns
      .filter((column) => typeof column.key === "number")
      .map((o) => o.key);
  },
});

export const DaysOfWeekControlFilterSelector = selector({
  key: "DaysOfWeekControlFilterSelector",
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.columns
      .filter((column) => daysOfWeekArray.indexOf(column.key) > -1)
      .map((o) => o.key);
  },
});

export const AccountInfoControlSelector = selector({
  key: "AccountInfoControlSelector",
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.columns.filter(
      (column) =>
        [...studentInfoArray, ...accountInfoArray].indexOf(column.key) > -1
    );
  },
});

export const DaysOfWeekControlSelector = selector({
  key: "DaysOfWeekControlSelector",
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.columns.filter(
      (column) => daysOfWeekArray.indexOf(column.key) > -1
    );
  },
});

export const FormControlSelector = selector({
  key: "FormControlSelector",
  get: ({ get }) => {
    const studentReportControls = get(StudentReportControls);
    return studentReportControls.columns.filter(
      (column) => typeof column.key === "number"
    );
  },
});

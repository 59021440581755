import * as React from "react";
import { useRecoilState } from "recoil";
// import DatePicker from "react-datepicker";
import { StudentReportControls } from "../../../../atoms";
import DatePicker from "../../../../components/DatePicker";

export default function SingleDate() {
  const [controls, setControls] = useRecoilState(StudentReportControls);

  function handleChange(newValue) {
    setControls({
      ...controls,
      filters: {
        ...controls.filters,
        startDate: "",
        endDate: "",
        date: newValue,
      },
    });
  }

  return (
    <DatePicker
      placeholderText="Single Date"
      selected={controls.filters.date}
      onChange={handleChange}
    />
    // <LocalizationProvider dateAdapter={AdapterDateFns}>
    //   <DatePicker
    //     label="Single Date"
    //     value={controls.filters.date}
    //     onChange={handleChange}
    //     PopperProps={{ anchorEl: customInputRef.current }}
    //     slots={{
    //       textField: (params) => <TextField size="small" {...params} />,
    //     }}
    //     // renderInput={(params) => {
    //     //   console.log("PARAMS ===============================");
    //     //   console.log(params);
    //     //   return <TextField size="small" {...params} />;
    //     // }}
    //   />
    // </LocalizationProvider>
  );
}
